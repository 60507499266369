import React from 'react';
import ReactDOM from 'react-dom/client';
import { useEffect } from 'react';
import { useLocation, BrowserRouter as Router, Routes, Route, Outlet, NavLink } from "react-router-dom";
import Loader from './loader.js';
import './assets/css/tlm.css';
import MainMenu from './mainMenu.js';
import Home from './pages/home.js';
import Services from './pages/services.js';
import ServiceIndex from './pages/servicesIndex.js';
import MLA from './pages/MLA.js';
import LBB from './pages/LBB.js';
import LLB from './pages/LLB.js';
import TiAP from './pages/TiAP.js';
import ACEE from './pages/ACEE.js';

import About from './pages/about.js';
import Contact from './pages/contact.js';
import NoPage from './pages/nopage.js';
import Footer from './footer.js';

function Content() {
  
  function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }

  return (
  <>
    <Router>  
      <ScrollToTop />
      <div className="appScreen">

        <MainMenu />

        <div className="actual">


          <Routes>
            <Route path="/" element={<Home />} ></Route>
            <Route path="/home" element={<Home />} ></Route>
            <Route path="/services" element={<Services />} >
              <Route index element={<ServiceIndex />} />
              <Route path="/services/master-legislative-affairs" element={<MLA />} />
              <Route path="/services/legislative-business-basics" element={<LBB />} />
              <Route path="/services/the-leadman-legislative-brunch" element={<LLB />} />
              <Route path="/services/the-indispensable-aide-package" element={<TiAP />} />
              <Route path="/services/advanced-career-evolution-excellence" element={<ACEE />} />

            </Route>
            <Route path="/about-us" element={<About />} ></Route>
            <Route path="/contact-us" element={<Contact />} ></Route>
            <Route path="*" element={<NoPage />} ></Route>
          </Routes>
                            
          {/*close actual app screen*/}
        </div>

        <Footer />

      </div>
    </Router>
  </>
  );
}


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {favoritecontent: <Loader/>};
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({favoritecontent: <Content/>})
    }, 0)
  }
  
  render() {
    return (
      <Content />
    );
  }
}

const preloader = ReactDOM.createRoot(document.getElementById('TLM'));
preloader.render(<App/>);