import React from 'react';
import img from './assets/images/The Lead Man Course b 2.jpg';
import { Link } from "react-router-dom";


function LBB() {

    return (
        <section className="columns grid100">            
            <div className="columns50">
                <div className="card">
                    <img src={img} alt="" className="serviceImg"  />
                </div>
            </div>

            <div className="columns50">
                <h3 className="serviceTitle red">The Legislative Business Basics (LBB)</h3>
                <div className="spacer20"></div>
                <p className="">
                    The business of Legislation requires knowledge of 
                    basic tools, terminologies, orientation among others. 
                    LBB is suitable for acclimatization of the legislative 
                    practice including legislative workplace culture and 
                    expectations. <br /><br/>
                    This basic yet important prerequisite knowledge 
                    form the basic skill for those who seek to go into 
                    legislation or aide the legislative business. 
                </p>
                <div className="spacer20"></div>
                <Link to=""><button className="button">Join Next Cohort</button></Link>
            </div>


        </section>
    );
}

export default LBB;