import React from 'react';
import { Link } from "react-router-dom";
import './assets/css/about.css';
import divider from './assets/css/divider.svg';
import { ScrollingTextBg, Contact } from './assets/modules.js';
import { MdOutlineArrowDropDown } from 'react-icons/md';


function ContactUs() {

    return (
        <>
            {/*///////////////////////////////////////EMPOWERING/////////////////////////////////////////////////////////*/}

            <section className=""><div className="spacer50 pt0 pb0"></div>
            <h2 className="pad center">Your <span className="red">transformation</span> starts here.</h2>
            </section>    

            { /*/////// CONTACT.    CONTACT.       CONTACT           CONTACT           */ }
            
            <Contact />
        </>
    );
}

export default ContactUs;