import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './assets/css/home.css';
import divider from './assets/css/divider.svg';
import { ScrollingTextBg, Contact } from './assets/modules.js';
import Francis from './assets/images/Francis Olorunyomi.png';
import { MdOutlineArrowCircleLeft, MdOutlineArrowCircleRight, MdOutlineArrowDropDown, MdOutlineArrowRightAlt, MdOutlineEditNote, MdOutlineLightbulb, MdOutlinePaid, MdOutlinePhone } from 'react-icons/md';

function Home() {
  const [position, setPosition] = useState(0); // Initialize position

  const maxScroll = 1200; // Maximum scroll value
  const totalWidth = 1150; // Width of workflow SVG

  const slideLeft = () => {
    setPosition((prevPosition) => Math.min(prevPosition + 400, 0)); // Slide left by 400px, not exceeding 0
  };

  const slideRight = () => {
    setPosition((prevPosition) => Math.max(prevPosition - 400, -maxScroll)); // Slide right by 400px, not exceeding -1200
  };

  const isAtLeftLimit = position === 0;
  const isAtRightLimit = position <= -maxScroll;

  // Calculate fill percentage for the progress bar
  const fillPercentage = ((-position / maxScroll) * 100) || 0; // Percentage based on scroll position
  const mFillPercentage = ((-((3 * position)+150) / maxScroll) * 100) || 0; // Percentage based on scroll position
  const fillColor = isAtRightLimit ? 'red' : 'grey'; // Change color to grey at max scroll

  

  return (
  <>
    {/*/////////////////////////////////////HERO///////////////////////////////////////////////////////////*/}
    
    <video  width="1440" autoPlay muted loop playsInline className="heroVideo">
      <source src="https://res.cloudinary.com/dzfqshhzu/video/upload/v1731180202/black_business_spotlight_mobile_1080p_gijgtl.mp4" type="video/mp4"></source>
      Your browser does not support the video
    </video>
    
    <aside className="spacer20 heroVideoSection">
      <div className="hero">
        <div className="heroLeft spacer100"><div className="spacer100"></div>
          <h1>Be better than <span style={{color:'#ff0000'}}>good</span> enough<span style={{color:'#ff0000'}}>.</span></h1>  
          <div className="mspacer"></div>
          <p className="mspacer">Leadership training for legislators, entrepreneurs and young professionals that equip you for success.</p>
          <div className="spacer50 mspacer"><div className="mspacer"></div>
            <Link to="/contact-us">
              <button className="button">Help me get better
              </button>
            </Link>
          </div> 
        </div>
        <div className="heroRight nooverflowx">
          
        </div>
      </div>
      <div className="flex flcenter nooverflowx" id="divider"></div>
    </aside>
    
    {/*///////////////////////////////////////EMPOWERING/////////////////////////////////////////////////////////*/}
    <section className=""><div className="spacer50"></div>
      <h2 className="pad">Empowering you with <span className="red">essential soft skills</span> for personal and professional growth</h2>
      <div className="columns">
        <div className="columns50 mHide"></div>
        <div className="columns50 spacer50">
          <p>Our comprehensive programs and expert guidance help you develop crucial abilities such as communication, leadership, 
            adaptability, and teamwork. We believe that mastering these skills is the key to unlocking your full potential and 
            achieving success in today's dynamic world. Your aspiration to excel in your field finds its partner in theleadman - 
            together, we'll turn your vision into reality.
          </p>
        </div>
      </div>
      <div className="spacer100"></div>
    </section>        
    {/*///////////////////////////////////////COUNTERS/////////////////////////////////////////////////////////*/}
    <section className="blackBg ">
      <h2 className="pad mcenter">After engaging with Theleadman, our clients <span className="red">typically experience...</span></h2>
      <div className="columns mHide grid20 spacer100">
        <div className="columns25 counter flcenter row">
          <span className="counterNumber red">+70%</span>
          <p className="">increase in productivity</p>
        </div>
        <div className="columns25 counter flcenter row">
          <span className="counterNumber red">+50%</span>
          <p className="">business growth</p>
        </div>
        <div className="columns25 counter flcenter row">
          <span className="counterNumber red">+20%</span>
          <p className="">returning customers</p>
        </div>
        <div className="columns25 counter flcenter row">
          <span className="counterNumber red">+54%</span>
          <p className="">interview invitations</p>
        </div>
      </div>
      <div className=" dHide grid20 spacer100">
        <div className="mcolumns">
          <div className="columns50 center row">
            <span className="counterNumber red">+70%</span>
            <p className="">increase in productivity</p>
          </div>
          <div className="columns25 center row">
            <span className="counterNumber red">+50%</span>
            <p className="">business growth</p>
          </div>
        </div>
        <div className="mcolumns">
          <div className="columns25 center row">
            <span className="counterNumber red">+20%</span>
            <p className="">returning customers</p>
          </div>
          <div className="columns25 center row">
            <span className="counterNumber red">+54%</span>
            <p className="">interview invitations</p>
          </div>
        </div>
      </div>
    </section>
    {/*///////////////////////////////////////SERVICES/////////////////////////////////////////////////////////*/}
    <div className="spacer50" >
      <ScrollingTextBg 
        text="Our Services"
        red=""
      /><br/>
      <h3 className="center mcenter overscroll">I'm interested in &nbsp; <span id="inpagemenu">Trainings<MdOutlineArrowDropDown /></span></h3>
    </div>
    <section><div className="spacer100"></div>
      <div className="columns grid20">
        <div className="columns3">
          <div className="sticky"></div>
        </div>
        <div className="columns3 grid20 row">
          <div className="card row grid20">
            <h4>Legislative Communication and Consult</h4>
            <p>At the heart of nation building is law making and social justice. Our legislative consulting services provide 
              invaluable insights and strategies to help your business not only comply with regulations but also thrive within them. 
              We stay ahead of legislative changes, ensuring your company is well-prepared to adapt and grow.
            </p>
            <div className="columns mcolumns columns100 spaceBetween">
              <div className="columns mcolumns number grid20">
                <span className="roundmarker"></span>
                <span className="">01</span>
              </div>
              <div className="columns mcolumns grid10">
              <Link to="/services#legislators" style={{fontFamily:"LatoLight"}}>Services</Link>
              <span className=""><MdOutlineArrowRightAlt /></span>
              </div>
            </div>

          </div>
          <div className="card row grid20">
            <h4>Unleashing Entrepreneurship Without Boundaries</h4>
            <p>Whether you're an emerging CEO embarking on a startup venture or leading a 
              well-established enterprise, the entrepreneurial path can feel like a solitary 
              voyage or an immensely rewarding one. The difference often lies in the support 
              you receive. Let us be the guiding hand that transforms your entrepreneurial 
              journey into a fulfilling and successful one, regardless of the stage you're in.
            </p>
            <div className="columns mcolumns columns100 spaceBetween">
              <div className="columns mcolumns number grid20">
                <span className="roundmarker"></span>
                <span className="mHide">03</span>
                <span className="dHide">02</span>
              </div>
              <div className="columns mcolumns grid10">
              <Link to="/services#entrepreneurship" style={{fontFamily:"LatoLight"}}>Services</Link>
              <span className=""><MdOutlineArrowRightAlt /></span>
              </div>
            </div>

          </div>
          <div className="card row grid20">
            <h4>Employability for Indispensability (Personal | Career Development)</h4>
            <p>In a world where traditional qualifications and backgrounds no longer guarantee 
              career or business success, distinguishing yourself becomes paramount. Gain a 
              competitive edge with our skill-building programs tailored to empower you with 
              the indispensable qualities that set you apart.
              Discover how to truly stand out in today's dynamic landscape.
            </p>
            <div className="columns mcolumns columns100 spaceBetween">
              <div className="columns mcolumns number grid20">
                <span className="roundmarker"></span>
                <span className="mHide">05</span>
                <span className="dHide">03</span>
              </div>
              <div className="columns mcolumns grid10">
                <Link to="/services#employability" style={{fontFamily:"LatoLight"}}>Services</Link>
                <span className=""><MdOutlineArrowRightAlt /></span>
              </div>
            </div>

          </div>
        </div>


        <div className="columns3 row grid20">
          <div className="card row grid20 ">
            <h4>Visionary Leadership</h4>
            <p>In the world of leadership, knowing the way and inspiring others to follow is paramount.
              At The LeadMan Africa, we specialize in guiding you through the intricate path of leadership. 
              Our expertise lies in translating your leadership vision into tangible reality.
              Join us as we navigate this journey together, setting the course for your leadership success.
            </p>
            <div className="columns mcolumns columns100 spaceBetween">
              <div className="columns mcolumns number grid20">
                <span className="roundmarker"></span>
                <span className="mHide">02</span>
                <span className="dHide">04</span>
              </div>
              <div className="columns mcolumns grid10">
              <Link to="/services#entrepreneurship" style={{fontFamily:"LatoLight"}}>Services</Link>
              <span className=""><MdOutlineArrowRightAlt /></span>
              </div>
            </div>

          </div>
          <div className="card row grid20">
            <h4>Workforce Enhancement</h4>
            <p>An ineffective or underperforming team can be a costly burden for any brand. In today's competitive 
              landscape, staying ahead demands assembling a team equipped with the essential tools to excel in your 
              industry, ensuring peak performance and productivity. Let us assist you in building a high-performing 
              team that propels your brand to new heights.
            </p>
            <div className="columns mcolumns columns100 spaceBetween">
              <div className="columns mcolumns number grid20">
                <span className="roundmarker"></span>
                <span className="mHide">04</span>
                <span className="dHide">05</span>
              </div>
              <div className="columns mcolumns grid10">
              <Link to="/services#employability" style={{fontFamily:"LatoLight"}}>Services</Link>
              <span className=""><MdOutlineArrowRightAlt /></span>
              </div>
            </div>

          </div>
          <div className="card row grid20">
            <h4>Resources</h4>
            <p>Stay ahead in your career and educational journey 
              with real-time updates on the latest scholarship and career opportunities.
            </p>
            <div className="columns mcolumns columns100 spaceBetween">
              <div className="columns mcolumns number grid20">
                <span className="roundmarker"></span>
                <span className="">06</span>
              </div>
              <div className="columns mcolumns grid10">
              <Link to="/services#entrepreneurship" style={{fontFamily:"LatoLight"}}>Services</Link>
              <span className=""><MdOutlineArrowRightAlt /></span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

    {/*///////////////////////////////////////WORKFLOW/////////////////////////////////////////////////////////*/}

    <section className="nooverflowx"><div className="spacer100 mspacer"></div>
      <h3 className="center mspacer">Our <span className="red">Workflow.</span></h3>
      <div className="mspacer"></div>
      <div className="columns mcolumns spaceAround mspacer">
        <button 
          className="workflowbtn round" 
          onClick={slideLeft} 
          disabled={isAtLeftLimit} // Disable button if at right limit
          style={{ opacity: isAtLeftLimit ? 0.5 : 1 }} // Grey out button if at right limit
        ><MdOutlineArrowCircleLeft style={{fontSize:"30px", margin:"0"}} />
        </button>

        <svg  xmlns="http://www.w3.org/2000/svg" height="500" width="1150" className="mHide workflow" fill="none" >
          <g className="workflow" style={{ transform: `translateX(${position}px)`, transition: 'transform 0.3s ease' }}>
            {/*Progress Lines*/}
            <rect x="0" y="248" height="4" width="150" fill="#f00" style={{zIndex:"1"}}/>
            <rect x="200" y="248" height="4" width="350" fill="#ffffff50" />
            <rect x="600" y="248" height="4" width="350" fill="#ffffff50" />
            <rect x="1000" y="248" height="4" width="350" fill="#ffffff50" />
            <rect x="1400" y="248" height="4" width="150" fill="#fffffff50" />

            <rect x="200" y="248" width={`${fillPercentage}%`} height="4" fill="#f00" />


            <circle cx="175" cy="248" r="25" fill="#ffffffdd" />
            <circle cx="575" cy="248" r="25" fill="#ffffffdd" />
            <circle cx="975" cy="248" r="25" fill="#ffffffdd" />
            <circle cx="1375" cy="248" r="25" fill="#ffffffdd" />

            <MdOutlineEditNote x="160" y="233" fill="#333" style={{fontSize:"30px"}} />
            <MdOutlinePaid x="560" y="233" fill="#333" style={{fontSize:"30px"}} />
            <MdOutlineLightbulb x="960" y="233" fill="#333" style={{fontSize:"30px"}} />
            <MdOutlinePhone x="1360" y="233" fill="#333" style={{fontSize:"30px"}} />

            {/*Text Box below*/}
            <text x="50" y="150" fill="#ffffff" style={{fontFamily:"LatoBold", fontWeight:"400"}}>Two Weeks before Project Delivery</text>
            <polygon points="175,305 155,325 195,325" fill="#333" />
            <rect x="0" y="325" rx="20" ry="20" height="100" width="350" fill="#333" />
            <text x="50" y="375" fill="#ffffffaa" style={{fontFamily:"Manrope", fontWeight:"400"}}>Statement of Interest | Notification</text>

            <text x="460" y="150" fill="#ffffff" style={{fontFamily:"LatoBold", fontWeight:"400"}}>Two | One Week to Project Delivery</text>
            <polygon points="575,305 555,325 595,325" fill="#333" />
            <rect x="400" y="325" rx="20" ry="20" height="100" width="350" fill="#333" />
            <text x="460" y="375" fill="#ffffffaa" style={{fontFamily:"Manrope", fontWeight:"400"}}>Financial Mobilization: 60% - 80%</text>

            <text x="920" y="150" fill="#ffffff" style={{fontFamily:"LatoBold", fontWeight:"400"}}>Delivery Week</text>
            <polygon points="975,305 955,325 995,325" fill="#333" />
            <rect x="800" y="325" rx="20" ry="20" height="100" width="350" fill="#333" />
            <text x="910" y="375" fill="#ffffffaa" style={{fontFamily:"Manrope", fontWeight:"400"}}>Running of Project</text>

            <text x="1250" y="150" fill="#ffffff" style={{fontFamily:"LatoBold", fontWeight:"400"}}>Two Weeks after Project Delivery</text>
            <polygon points="1375,305 1355,325 1395,325" fill="#333" />
            <rect x="1200" y="325" rx="20" ry="20" height="100" width="350" fill="#333" />
            <text x="1350" y="375" fill="#ffffffaa" style={{fontFamily:"Manrope", fontWeight:"400"}}>Balance</text>
          </g>

        </svg>

        <button 
          className="workflowbtn round" 
          onClick={slideRight} 
          disabled={isAtRightLimit} // Disable button if at right limit
          style={{ opacity: isAtRightLimit ? 0.5 : 1 }} // Grey out button if at right limit
        ><MdOutlineArrowCircleRight style={{fontSize:"30px"}} />
        </button>

      </div>
      <div className="flex flcenter dHide">
      <svg  xmlns="http://www.w3.org/2000/svg" height="300" width="400" className="dHide mcenter workflow" fill="none"  >
        <g style={{ transform: `translateX(${position}px)`, transition: 'transform 0.3s ease' }}>
          {/*Progress Lines*/}
          <rect x="0" y="98" height="4" width="150" fill="#f00" />
          <rect x="200" y="98" height="4" width="350" fill="#ffffff50" />
          <rect x="600" y="98" height="4" width="350" fill="#ffffff50" />
          <rect x="1000" y="98" height="4" width="350" fill="#ffffff50" />
          <rect x="1400" y="98" height="4" width="150" fill="#fffffff50" />

          <rect x="200" y="98" width={`${mFillPercentage}%`} height="4" fill="#f00" />

          <circle cx="175" cy="98" r="25" fill="#ffffffdd" />
          <circle cx="575" cy="98" r="25" fill="#ffffffdd" />
          <circle cx="975" cy="98" r="25" fill="#ffffffdd" />
          <circle cx="1375" cy="98" r="25" fill="#ffffffdd" />

          <MdOutlineEditNote x="160" y="83" fill="#333" style={{fontSize:"30px"}} />
          <MdOutlinePaid x="560" y="83" fill="#333" style={{fontSize:"30px"}} />
          <MdOutlineLightbulb x="960" y="83" fill="#333" style={{fontSize:"30px"}} />
          <MdOutlinePhone x="1360" y="83" fill="#333" style={{fontSize:"30px"}} />

          {/*Text Box below*/}
          <text x="50" y="50" fill="#ffffff" style={{fontFamily:"LatoBold", fontWeight:"400"}}>Two Weeks before Project Delivery</text>
          <polygon points="175,143 155,163 195,163" fill="#333" />
          <rect x="0" y="163" rx="20" ry="20" height="100" width="350" fill="#333" />
          <text x="50" y="215" fill="#ffffffaa" style={{fontFamily:"Manrope", fontWeight:"400"}}>Statement of Interest | Notification</text>

          <text x="460" y="50" fill="#ffffff" style={{fontFamily:"LatoBold", fontWeight:"400"}}>Two | One Week to Project Delivery</text>
          <polygon points="575,143 555,163 595,163" fill="#333" />
          <rect x="400" y="163" rx="20" ry="20" height="100" width="350" fill="#333" />
          <text x="460" y="215" fill="#ffffffaa" style={{fontFamily:"Manrope", fontWeight:"400"}}>Financial Mobilization: 60% - 80%</text>

          <text x="920" y="50" fill="#ffffff" style={{fontFamily:"LatoBold", fontWeight:"400"}}>Delivery Week</text>
          <polygon points="975,143 955,163 995,163" fill="#333" />
          <rect x="800" y="163" rx="20" ry="20" height="100" width="350" fill="#333" />
          <text x="910" y="215" fill="#ffffffaa" style={{fontFamily:"Manrope", fontWeight:"400"}}>Running of Project</text>

          <text x="1250" y="50" fill="#ffffff" style={{fontFamily:"LatoBold", fontWeight:"400"}}>Two Weeks after Project Delivery</text>
          <polygon points="1375,143 1355,163 1395,163" fill="#333" />
          <rect x="1200" y="163" rx="20" ry="20" height="100" width="350" fill="#333" />
          <text x="1350" y="215" fill="#ffffffaa" style={{fontFamily:"Manrope", fontWeight:"400"}}>Balance</text>
        </g>
      </svg>
      </div>
      <div className="spacer100"></div>
    </section>

    {/*///////////////////////////////////////TESTIMONIAL/////////////////////////////////////////////////////////*/}

    <div className="columns">
      <div className="columns50 redBg fullHeight background">

      </div>
    </div>
    <section className="">
      <div className="pad">
        <div className="testimonialBox columns grid100">
          <div className="columns30 right">
            <div className="center">
              <div clasName="center"><img src={Francis} className=" testimonialImage round" /></div><br/>
              <h6 className="testimonialGiverName">Francis Olorunyomi</h6>
              <p className="testimonialGiverTitle">CEO, Gryn Index Initiative</p>
            </div>

          </div>
          <div className="columns columns70 left">
            <div className="columns grid20">
              <div className="columns80 grid20">
                <h6 className="testimonialTitle mcenter">Improved Staff Productivity</h6><br/>
                <p className="testimonialText grey">"My company has found an enormous growth 
                  since working with TheLeadman Africa. <br/><br/>
                  My Team’s performance has greatly improved 
                  and I am pleased with the way they now 
                  engage with customers and among 
                  themselves. The synergy now makes room for 
                  productivity and growth of the Company."
                </p>
              </div>
              <div className="columns20">
                <span className="togglearrow">  </span>
              </div>
            </div>
            
          </div>

        </div>
      </div>
    </section>

{/*///////////////////////////////////////CONTACT US/////////////////////////////////////////////////////////*/}
    <Contact />

    
            {/*/////////////////FOOTER///////////////////*/}     
  </>  
  )  
}

export default Home;