import React from 'react';
import img from './assets/images/The Lead Man Course c 2.jpg';
import { Link } from "react-router-dom";


function LLB() {

    return (
        <section className="columns grid100 start">            
            <div className="columns50">
                <div className="card">
                    <img src={img} alt="" className="serviceImg"  />
                </div>
            </div>

            <div className="columns50">
                <h3 className="serviceTitle red">The Leadman Legislative Brunch (LLB)</h3>
                <div className="spacer20"></div>
                <p className="">
                    Have a busy schedule? But you still want a feel of what 
                    the Legislative business is all about? What do they 
                    even do in these houses of Assemblies sef? You want 
                    to know? Then this course is for you. Enrol to get 
                    familiar and stop being a stranger. Don’t be a stranger 
                    in the business of Legislation. <br/><br/>
                    Designed with your busy schedule in mind, our LLB 
                    will equip you with enough needed to understand 
                    the legislative business.
                </p>
                <div className="spacer20"></div>
                <Link to=""><button className="button">Join Next Cohort</button></Link>
            </div>


        </section>
    );
}

export default LLB;