import React, { useState } from 'react';
import { Link } from "react-router-dom";
import styles from './assets/css/mainMenu.module.css';
import { MdMail, MdOutlineClose, MdOutlineLocationOn, MdOutlineMenu, MdOutlineMenuBook, MdOutlineMenuOpen, MdPhone } from 'react-icons/md';

function MainMenu() {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle the mobile menu open/close state
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };  
  
  const closeMenu = () => setIsOpen(false);

  
  const menu = (
    <>
      <div className={styles.menu + ' ' + 'mHide' + ' ' + 'topmost'} id="topmost">
        <div className={styles.menuContainer + ' ' + styles.borderBottom} >
          <div className={styles.menuItemsWrapper}>
            <ul className={styles.menuItems + ' ' + styles.right}>
              <li className={styles.menuItem}><Link to='/about-us'>About Us</Link></li>
              <li className={styles.menuItem}><Link to='/services'>Our Services</Link></li>
            </ul>
          </div>
          <div className={styles.logo}>
            <Link to='/' style={{cursor:'pointer'}}>
              <div className={styles.blogo}> 
                <img alt="theleadman logo" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1708076254/IMG_0186-removebg-preview.png" height="50" id="#logo" />
              </div>  
            </Link>
          </div>
          
          <div className={styles.menuItemsWrapper}>
            <ul className={styles.menuItems + ' ' + styles.left + ' ' + 'vcenter'}>
              <li className={styles.menuItem}><Link to='/home'>Portfolio</Link></li>
              <li className=""><Link to='/contact-us'><button className={styles.menuButton}>Help Me Get Better</button></Link></li>
            </ul>
          </div>
        </div>
        <div className={styles.bottomContainer + ' ' + styles.borderBottom}>
          <span><MdOutlineLocationOn /> 10, Olaleke Taiwo Street, Ikeja, lagos.</span>
          <Link to="tel:234703904100"><MdPhone /> +234703904100</Link>
          <Link to="mailto:info@theleadman.org"><MdMail /> info@theleadman.org</Link>
        </div>
      </div>

      
      <div className={styles.menu + ' ' + 'dHide' + ' ' + 'topmost' + ' ' + 'columns100'} id="mtopmost">
        <div className={'mcolumns' + ' ' + 'spaceBetween' + ' '  + styles.borderBottom + styles.menuContainer + ' ' + 'vcenter'} >
          <div className="columns80">
            <Link to='/' style={{cursor:'pointer'}}>
              <div className={styles.blogo}> 
                <img alt="theleadman logo" src="https://res.cloudinary.com/dzfqshhzu/image/upload/v1708076254/IMG_0186-removebg-preview.png" height="50" id="#logo" />
              </div>  
            </Link>
          </div>
          <div className="columns20 right">
            <button onClick={toggleMenu} className={styles.mtoggle}>{isOpen ? <MdOutlineClose /> : <MdOutlineMenuOpen />} </button>
          </div>
        </div>

        <div className={`${styles.mmenu} row grid50 vcenter ${isOpen ? styles.open : ''}`} id="mmenuBox">
          <Link to='/' onClick={closeMenu}>Home</Link>
          <Link to='/about-us'onClick={closeMenu}>About Us</Link>
          <Link to='/services'onClick={closeMenu}>Our Services</Link>
          <Link to='/contact-us'onClick={closeMenu}><button className={styles.menuButton}>Help Me Get Better</button></Link>

        </div>
      </div>

    </>
  );

  return menu
}

window.addEventListener('scroll', () => {
  const verticalScrollPx = window.scrollY || window.pageYOffset;
  const menu = document.getElementById("topmost");
  const mmenu = document.getElementById("mtopmost");

  if (verticalScrollPx < 20) {
    menu.style.backgroundColor = 'transparent';
    mmenu.style.backgroundColor = 'transparent';
  } else if (verticalScrollPx > 20 && verticalScrollPx < 1000) {
    menu.style.backgroundColor = '#222222';
    mmenu.style.backgroundColor = '#222222';
  } else {
    menu.style.backgroundColor = '#222222';
    mmenu.style.backgroundColor = '#222222';
  }
});



export default MainMenu;
