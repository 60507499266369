import React from 'react';
import { Link } from "react-router-dom";


function NoPage() {

    return (
        <div className="noMenu">
            <div className="pg-hero columns hmHeroBg padLeft50">
                <div className="heroLeft columns70 bigHeading ">
                <h1>Hey Leader 👋🏻 </h1>
                    <div className="columns80 spacer20">
                        <p> We are having trouble loading that page.<br/>
                            Let's help you find what you need.
                        </p>
                        <div className="mspacer"></div>
                        <div className="spacer50 columns mcolumns grid50">
                            <Link to="/home"><button className="button">Home
                                </button>
                            </Link>
                            <Link to="/home"><button className="button">Help me get better
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            

        </div>
    );
}

export default NoPage;