import React from 'react';
import './assets/css/services.css';
import { Outlet } from 'react-router-dom';

function Services() {

    return (
        <>
            <Outlet />
        </>
    );
}

export default Services;