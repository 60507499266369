import contactForm from './contactForm.js';
import './css/home.css';

const ScrollingTextBg = ({ text, red })=> {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" height="300" width="1440" fill="none" className="scrollingTextBg" >
        <text fill="#313131" x="50" y="260" className="scrollingTextBgText mHide"  style={{opacity:"0.5"}}>
          {text}<tspan fill="#f00" style={{opacity:"0.2", fontSize:""}}>{ red } &nbsp;&nbsp;</tspan>
          {text}<tspan fill="#f00" style={{opacity:"0.2", fontSize:""}}>{ red } &nbsp;</tspan>
          {text}<tspan fill="#f00" style={{opacity:"0.2", fontSize:""}}>{ red } &nbsp;</tspan>
          {text}<tspan fill="#f00" style={{opacity:"0.2", fontSize:""}}>{ red } &nbsp;</tspan>
          <animate
            attributeName="x"
            begin="1s"
            dur="50s"
            from="50"
            to="-1440"
            repeatCount="indefinite" 
          />
        </text>
        <text fill="#313131" x="50" y="100" className="scrollingTextBgText dHide"  >
        {text}<tspan fill="#f00" style={{opacity:"0.2", fontSize:""}}>{ red } &nbsp;&nbsp;</tspan>
          {text}<tspan fill="#f00" style={{opacity:"0.2", fontSize:""}}>{ red } &nbsp;</tspan>
          {text}<tspan fill="#f00" style={{opacity:"0.2", fontSize:""}}>{ red } &nbsp;</tspan>
          {text}<tspan fill="#f00" style={{opacity:"0.2", fontSize:""}}>{ red } &nbsp;</tspan>
          <animate
            attributeName="x"
            begin="1s"
            dur="50s"
            from="50"
            to="-1440"
            repeatCount="indefinite" 
          />
        </text>
      </svg>
    </>
  )
}

const Contact = ()=> {
  return (
    <div className="spacer">
    <ScrollingTextBg
      text="Contact Us"
      red="."
    />
    <section className="columns start pt0">
      <div className="columns50 row center grid20 spacer100"><div className="spacer50"></div>
        <h3 className="overscroll mspacer">It's all a message away</h3>
        <p className="bigText wt100">Your Vision ∞ Our Expertise</p>
      </div>
      <div className="columns50">
        {contactForm()}
      </div>
    </section>
    
  </div>
  )
}

export { ScrollingTextBg, Contact }