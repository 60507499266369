import React from 'react';
import img from './assets/images/The Lead Man Course e 2.jpg';


function ACEE() {

    return (
        <section className="columns grid100 start">            
            <div className="columns50">
                <div className="card">
                    <img src={img} alt="" className="serviceImg"  />
                </div>
            </div>

            <div className="columns50">
                <h3 className="serviceTitle red">Advanced Career Evolution Excellence (A.C.E.E)</h3>
                <div className="spacer20"></div>
                <p className="">Since 2020, the reality of work has moved from what we used to 
                    know. The future of work became our reality in an accelerated 
                    wave as an aftermath of the COVID-19 pandemic. <br/><br/>
                    The pandemic accelerated existing trends in remote work, e- commerce, and automation, with an estimated 30 percent of 
                    the World workforce potentially needing to switch occupations.
                    However, the sad reality is many are not prepared for this switch. <br/><br/>
                    The new demand and opportunities opening up now requires 
                    new set of skills suitable for this evolution that has never before 
                    been witnessed in our workforce history.<br/><br/>
                    The LeadMan A.C.E.E is designed to prepare fresh and recent 
                    graduates, ready to launch a 21st century inclined 
                    career|business, compatible with the reality and future of work as this new wave keep 
                    evolving everyday. <br/><br/>
                    How prepared are you for this evolution? 
                    The Advanced Career Evolution Excellence (ACEE) program is an 8week hybrid program that 
                    prepares you for this evolutionary reality. A.C.E.E is especially crafted to empower you in 
                    navigating a rewarding path for yourself in this new trend.
                </p>
                <div className="spacer20"></div>
                <a to=""><button className="button">Join Next Cohort</button></a>
            </div>


        </section>
    );
}

export default ACEE;